/** @jsx jsx */
import { Box, Grid, jsx, Flex, Input, Label, Spinner } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useRef, useState, useEffect } from "react"
import {
  useDispatchCurrentUser,
  useCurrentUser,
} from "../components/CurrentUser"
import { callApi, callApiNoAuth } from "../utils"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { navigate, Link } from "gatsby"
import LoginLayout from "../components/loginLayout"
import LoginLogo from "../components/LoginLogo"
import Tooltip from "../components/Tooltip"
import { FaQuestionCircle } from "react-icons/fa"

//yup schema
let schema = Yup.object().shape({
  password: Yup.string().matches(
    /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/,
    {
      message: "Please enter a stronger password",
    }
  ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
})

const ResetPasswordPage = ({ location }) => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfRef = useRef()
  const [errorMsg, setErrorMsg] = useState(null)
  const [validationErrorMsg, setValidationErrorMsg] = useState(null)
  //this is to prevent double submits
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatchCurrentUser()
  const urlParams = new URLSearchParams(location.search)
  const resetCode = urlParams.get("code")
  // console.log("resetCode", resetCode)
  if (resetCode === null) {
    //because ssr
    if (typeof window !== "undefined") {
      navigate("/forgot-password")
    }
  }

  let currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      //because ssr
      if (typeof window !== "undefined") {
        navigate("/app")
      }
    }
    // console.log("currentUser", currentUser)
  }, [currentUser])

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitted(true)
    schema
      .validate(
        {
          password: passwordRef.current.value,
          passwordConfirmation: passwordConfRef.current.value,
        },
        { abortEarly: false }
      )
      .then(async function (valid) {
        //valid // => true
        // console.log("validation successful")

        setErrorMsg(null)
        if (resetCode === null) {
          setErrorMsg("There is no reset code.")
          setSubmitted(false)
        } else {
          try {
            const data = await callApiNoAuth("/auth/reset-password", "POST", {
              code: resetCode,
              password: passwordRef.current.value,
              passwordConfirmation: passwordConfRef.current.value,
            })

            if (!data.user) {
              //const message = `An error has occured: ${response.message[0].messages[0].message}`;
              // console.log(data)
              //throw new Error(message);
              //setErrorMsg(message)
              setSubmitted(false)
              throw `An error has occured: ${data.message[0]?.messages[0]?.message}`
            }

            // console.log("user password changed")
            // console.log(data)
            setErrorMsg(
              "user password changed successfully, redirecting you to login page"
            )
            setSubmitted(false)
            navigate("/login")

            // else {
            //     //const message = response.message[0].messages[0].message
            //     //setErrorMsg(`An error has occured: ${message}`)
            //     console.log(response)
            //     console.log('message',response.message[0].messages[0].message)
            //     //throw new Error(message)
            // }
            // if(!response.user) {
            //     throw "Cannot login.  Please try again"
            // }
            // //there is a user
            // dispatch({type: "LOGIN", user: response.user})
            // navigate('/app')
          } catch (err) {
            setErrorMsg(err)
            console.log(err)
            setSubmitted(false)
          }
        }
      })
      .catch(function (err) {
        // console.log(err.errors)
        setValidationErrorMsg(err.errors)
        console.log(err)
        setSubmitted(false)
      })
  }

  return (
    <LoginLayout>
      <div sx={styles}>
        <LoginLogo />

        <Box className="resetPassword">
          <h1>Reset Password</h1>
          <Box as="form" onSubmit={handleSubmit}>
            <fieldset id="password-reset" sx={{ border: "none", py: 3 }}>
              <p>Enter your new password below and click submit to reset.</p>
              <div className="field">
                <Label htmlFor="password">
                  New Password{" "}
                  <Tooltip
                    content="Passwords should be at least 8 characters long, include uppercase letter, a lowercase letter, a number and a symbol"
                    direction="right"
                  >
                    <FaQuestionCircle />
                  </Tooltip>
                </Label>
                <Input
                  ref={passwordRef}
                  type="password"
                  name="password"
                  id="password"
                />
              </div>
              <div className="field">
                <Label htmlFor="passwordConf">Confirm Password</Label>
                <Input
                  ref={passwordConfRef}
                  type="password"
                  name="passwordConf"
                  id="passwordConf"
                />
              </div>
            </fieldset>
            <div className="buttonRow">
              {submitted && <Spinner />}
              <Input type="submit" value="Submit" disabled={submitted} />
            </div>
            <div
              sx={{
                "& .error": {
                  color: "red",
                },
              }}
            >
              {errorMsg && <p className="error">{errorMsg}</p>}
              {validationErrorMsg &&
                validationErrorMsg.map(error => {
                  return <p className="error">{error}</p>
                })}
            </div>
            <div className="otherLinks">
              <Link to="/sign-up">Sign up</Link>
              <Link to="/login">Back To Login</Link>
            </div>
          </Box>
        </Box>
      </div>
    </LoginLayout>
  )
}

export default ResetPasswordPage

const styles = {
  "& .resetPassword": {
    margin: "0 auto",
    maxWidth: "50%",
    background: "white",
    padding: 4,
    "& p.error": {
      color: "red",
    },
    "input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover:enabled": {
        background: darken("accent", 0.1),
      },
      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3,
      },
    },
    "input[type=text]:focus-visible": {
      outline: "red",
    },
    a: {
      color: "accent",
    },
    ".otherLinks": {
      margin: "0 auto",
      mt: 3,
      textAlign: "center",
      a: {
        pr: 3,
      },
    },
  },
  "& .error": {
    color: "red",
  },
  "& .Tooltip-Wrapper": {
    ml: 2,
    "&:hover": {
      cursor: "pointer",
    },
    svg: {
      verticalAlign: "-0.16em",
    },
  },
  "& .field": {
    mb: 3,
  },
  "& .buttonRow svg": {
    color: "accent",
    textAlign: "center",
    mx: "auto",
    display: "block",
    mb: 2,
  },
}
